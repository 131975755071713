/**
 * Partners component
 */

import React from "react"
import "./partners.scss"

import foremImg from "../../images/leforem.png"
import dbsfImg from "../../images/dbsf.png"
import technociteImg from "../../images/technocite.png"
import eecampusImg from "../../images/ee-campus.png"
import enmieuxImg from "../../images/enmieux.png"
import coffeeImg from "../../images/coffee.png"

const Partners = () => {
  return (
    <aside id="partners" className="partners-row container small">
		<h2><span>Nos partenaires</span></h2>
		<ul>
			<li><a href="https://www.technocite.be/" target="_blank" rel="noopener noreferrer"><img src={technociteImg} alt="Technocité" /></a></li>
			<li><a href="https://www.ee-campus.be/" target="_blank" rel="noopener noreferrer"><img src={eecampusImg} alt="Eurometropolitan e-campus" /></a></li>
			<li><a href="https://dt.bosa.be/fr/dbsf2020" target="_blank" rel="noopener noreferrer"><img src={dbsfImg} alt="Digital Belgium Skills Fund" /></a></li>
			<li><a href="https://www.enmieux.be/" target="_blank" rel="noopener noreferrer"><img src={enmieuxImg} alt="En mieux / FSE / Wallonie" /></a></li>
			<li><a href="https://www.leforem.be/" target="_blank" rel="noopener noreferrer"><img src={foremImg} alt="Le Forem" /></a></li>
			<li><a href="https://coffeecoworking.eu/" target="_blank" rel="noopener noreferrer"><img src={coffeeImg} alt="Coffee Interreg" /></a></li>
		</ul>
	</aside>
  )
}

export default Partners
